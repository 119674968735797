import { TProduct } from '../Products/types'
import { TPromocode } from '../Promocodes/types'

export enum EOrder {
  Id = 'id',
  Status = 'status',
  PromoCode = 'promoCode',
  TransactionId = 'transactionId',
  CreatedAt = 'createdAt',
  PaymentMethod = 'paymentMethod',
  Consumer = 'consumer',
  TotalPrice = 'totalPrice',
  Product = 'product',
  UserIdLike = 'userIdLike',
  UserPhoneNumberLike = 'userPhoneNumberLike',
  MerchantUserId = 'merchantUserId',
  TransactionIdLike = 'transactionIdLike',
  ProductId = 'productId',
  BrandId = 'brandId',
  VariantId = 'variantId',
  Refundable = 'refundable',
  Miniprogram_transaction_detail = 'miniprogram_transaction_detail',
}

export enum EStatus {
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  FAILURE = 'FAILURE',
  SUCCESS = 'SUCCESS',
}

export enum EConsumer {
  Id = 'id',
  Phone = 'phone',
}

export enum EMiniprogramTransactionDetail {
  PaymentId = 'paymentId',
  PaymentRequestId = 'paymentRequestId',
  PaymentAmount = 'paymentAmount',
  Currency = 'currency',
  Value = 'value',
  PaymentTime = 'paymentTime',
  PaymentStatus = 'paymentStatus',
  Result = 'result',
  ResultStatus = 'resultStatus',
  ResultCode = 'resultCode',
  ResultMessage = 'resultMessage',
  Transactions = 'transactions',
  TransactionId = 'transactionId',
  TransactionTime = 'transactionTime',
  TransactionType = 'transactionType',
  TransactionAmount = 'transactionAmount',
  TransactionStatus = 'transactionStatus',
}

// Enums for predefined fields
export enum EPaymentStatus {
  Success = 'SUCCESS',
  Failed = 'FAILED',
  Pending = 'PENDING',
}

export enum EResultStatus {
  Success = 'S',
  Failure = 'F',
}

export enum ETransactionType {
  Payment = 'PAYMENT',
  Refund = 'REFUND',
  Other = 'OTHER',
}

export enum ETransactionStatus {
  Success = 'SUCCESS',
  Failed = 'FAILED',
  Pending = 'PENDING',
}

// Type definition using enums
export type TMiniprogramTransactionDetail = {
  [EMiniprogramTransactionDetail.PaymentId]: string
  [EMiniprogramTransactionDetail.PaymentRequestId]: string
  [EMiniprogramTransactionDetail.PaymentAmount]: {
    [EMiniprogramTransactionDetail.Currency]: string
    [EMiniprogramTransactionDetail.Value]: string
  }
  [EMiniprogramTransactionDetail.PaymentTime]: string
  [EMiniprogramTransactionDetail.PaymentStatus]: EPaymentStatus
  [EMiniprogramTransactionDetail.Result]: {
    [EMiniprogramTransactionDetail.ResultStatus]: EResultStatus
    [EMiniprogramTransactionDetail.ResultCode]: string
    [EMiniprogramTransactionDetail.ResultMessage]: string
  }
  [EMiniprogramTransactionDetail.Transactions]: {
    [EMiniprogramTransactionDetail.TransactionId]: string
    [EMiniprogramTransactionDetail.TransactionTime]: string
    [EMiniprogramTransactionDetail.TransactionType]: ETransactionType
    [EMiniprogramTransactionDetail.TransactionAmount]: {
      [EMiniprogramTransactionDetail.Currency]: string
      [EMiniprogramTransactionDetail.Value]: string
    }
    [EMiniprogramTransactionDetail.TransactionStatus]: ETransactionStatus
  }[]
}

type TConsumer = {
  id: string
  phone: string
}

type TStatus = [EStatus.PENDING] | [EStatus.CANCELLED] | [EStatus.FAILURE] | [EStatus.SUCCESS]

export type TOrder = {
  [EOrder.Id]: number
  [EOrder.PromoCode]: TPromocode
  [EOrder.Status]: TStatus
  [EOrder.TransactionId]: string
  [EOrder.CreatedAt]: Date
  [EOrder.PaymentMethod]: string
  [EOrder.Consumer]: TConsumer
  [EOrder.TotalPrice]: number
  [EOrder.Product]: TProduct
  [EOrder.Refundable]: boolean
  [EOrder.Miniprogram_transaction_detail]: TMiniprogramTransactionDetail | null
}
