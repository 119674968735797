import {
  List,
  Datagrid,
  NumberField,
  DateField,
  WrapperField,
  TextField,
  ImageField,
  DateTimeInput,
  SearchInput,
  WithRecord,
  BulkExportButton,
  BulkDeleteButton,
  TopToolbar,
  CreateButton,
  ExportButton,
} from 'react-admin'
import { Box } from '@mui/material'
import { EMatch, ETeam } from '@/modules/Matches/types'
import { EStadium } from '@/modules/Stadiums/types'
import { ESortOrder } from '@/types/data'
import { Can } from '@/context/AbilityContext'
import { ability } from '@/context/ability'
import { EEvent } from '@/modules/Concerts/types'

const matchesFilters = [
  <SearchInput source={EMatch.ArTitleLike} alwaysOn key={1} placeholder="Search (Arabic title)" />,
  <SearchInput source={EMatch.ArSubtitleLike} alwaysOn key={2} placeholder="Search (Arabic subtitle)" />,
  <SearchInput source={EMatch.TeamAId} key={3} placeholder="Search (Team A ID)" type="number" />,
  <SearchInput source={EMatch.TeamBId} key={4} placeholder="Search (Team B ID)" type="number" />,
  <SearchInput source={EMatch.StadiumId} key={5} placeholder="Search (Stadium ID)" type="number" />,
  <DateTimeInput source={EMatch.MinDateAndTime} key={6} label="Search (Min date)" />,
  <DateTimeInput source={EMatch.MaxDateAndTime} key={7} label="Search (Max date)" />,
]
interface NotPublishedStyleProps {
  backgroundColor: string
  color: string
  borderRadius: string
  padding: string
  width: string
  display: string
  justifyContent: string
}

const notPublishedStyle: NotPublishedStyleProps = {
  backgroundColor: '#ad2f44',
  color: 'white',
  borderRadius: '30px',
  padding: '5px',
  width: '6rem',
  display: 'flex',
  justifyContent: 'center',
}
const notPublishedStyleCSS: React.CSSProperties = notPublishedStyle

export const MatchesList = () => {
  const ListActions = () => (
    <TopToolbar>
      <Can I="create" a="matches">
        <CreateButton />
      </Can>
      <ExportButton />
    </TopToolbar>
  )
  return (
    <Can I="findAll" a="matches">
      <List
        filters={ability.can('findOne', 'matches') ? matchesFilters : []}
        sort={{ field: EMatch.CreatedAt, order: ESortOrder.Desc }}
        actions={<ListActions />}
      >
        <Datagrid
          rowClick={ability.can('findOne', 'matches') ? 'edit' : false}
          bulkActionButtons={
            <>
              <BulkExportButton />
              <Can I="remove" a="matches">
                <BulkDeleteButton />
              </Can>
            </>
          }
        >
          <NumberField source={EMatch.Id} textAlign="left" sortable />
          <TextField source={EMatch.EventKey} sortable={false} />
          <DateField source={EMatch.DateAndTime} showTime label={<span>Date and Time</span>} sortable={false} />
          {/* <DateField source={EMatch.PublishedAt} showTime label={<span>Published At</span>} sortable /> */}
          <WithRecord
            label="Published At"
            render={(record) => {
              switch (record[EMatch.PublishedAt]) {
                case null: {
                  return <div style={notPublishedStyleCSS}>Not Published</div>
                }
                default: {
                  return <DateField source={EMatch.PublishedAt} showTime label={<span>Published At</span>} sortable />
                }
              }
            }}
          />

          <TextField source={EMatch.ArTitle} label="Title (ar)" sortable />
          <TextField source={EMatch.ArSubtitle} label="Subtitle (ar)" sortable />
          <TextField source={EMatch.ArAddress} label="Adress (ar)" sortable={false} />
          <TextField source={`${EMatch.Event}.${EEvent.MaxAllowedTickets}`} label="Max Allowed Tickets" />
          <WrapperField label="Team A">
            <Box
              gridColumn="span 3"
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              margin="4px"
              border="1px solid red"
            >
              <Box marginBottom="12px">
                <p>
                  <b>Arab Name</b>
                </p>
                <TextField source={`${EMatch.TeamA}.${ETeam.ArName}`} sortable={false} />
              </Box>
              <Box marginBottom="12px">
                <p>
                  <b>Ku Name</b>
                </p>
                <TextField source={`${EMatch.TeamA}.${ETeam.KuName}`} sortable={false} />
              </Box>
              <Box marginBottom="12px">
                <ImageField
                  source={`${EMatch.TeamA}.${ETeam.Logo}`}
                  sx={{
                    '& img': {
                      maxWidth: 50,
                      maxHeight: 50,
                      objectFit: 'cover !important',
                      borderRadius: '50%',
                    },
                    display: 'flex',
                    marginRight: '8px',
                  }}
                />
              </Box>
            </Box>
          </WrapperField>
          <WrapperField label="Team B">
            <Box
              gridColumn="span 3"
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              margin="4px"
              border="1px solid blue"
            >
              <Box marginBottom="12px">
                <p>
                  <b>Arab Name</b>
                </p>
                <TextField source={`${EMatch.TeamB}.${ETeam.ArName}`} sortable={false} />
              </Box>
              <Box marginBottom="12px">
                <p>
                  <b>Ku Name</b>
                </p>
                <TextField source={`${EMatch.TeamB}.${ETeam.KuName}`} sortable={false} />
              </Box>
              <Box marginBottom="12px">
                <ImageField
                  source={`${EMatch.TeamB}.${ETeam.Logo}`}
                  sx={{
                    '& img': {
                      maxWidth: 50,
                      maxHeight: 50,
                      objectFit: 'cover !important',
                      borderRadius: '50%',
                    },
                    display: 'flex',
                    marginRight: '8px',
                  }}
                />
              </Box>
            </Box>
          </WrapperField>
          <WrapperField label="Stadium">
            <Box
              gridColumn="span 3"
              display="flex"
              alignItems="center"
              justifyContent="space-around"
              margin="4px"
              border="1px solid black"
            >
              <Box marginBottom="12px">
                <p>
                  <b>Arab Name</b>
                </p>
                <TextField source={`${EMatch.Stadium}.${EStadium.ArName}`} sortable={false} />
              </Box>
              <Box marginBottom="12px">
                <p>
                  <b>Ku Name</b>
                </p>
                <TextField source={`${EMatch.Stadium}.${EStadium.KuName}`} sortable={false} />
              </Box>
              <Box marginBottom="12px">
                <p>
                  <b>Chart Key</b>
                </p>
                <TextField source={`${EMatch.Stadium}.${EStadium.ChartKey}`} sortable={false} />
              </Box>
            </Box>
          </WrapperField>
        </Datagrid>
      </List>
    </Can>
  )
}
