import { useState, useEffect } from 'react'
import { axiosAuthInstance } from '../../services/api/axios'
import AllowedPageItem from '../AllowedPageItem/AllowedPageItem'
import { useParams } from 'react-router-dom'
import { API_AUTH_BASE_URL } from '@/services/api/consts'
import { Skeleton } from '@mui/lab'
import { Box } from '@mui/material'

const AllowedPages = () => {
  const params = useParams()
  const id = params['*']
  const [responseData, setResponseData] = useState<string[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosAuthInstance.get(`${API_AUTH_BASE_URL}/dashboard/pages/single/${id}`)
        setResponseData([response.data.iFrameURL])
        setLoading(false)
      } catch (error) {
        console.error('----Error fetching data:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [id])
  if (loading)
    return (
      <Box>
        <Skeleton />
        <Skeleton animation="pulse" />
        <Skeleton animation={false} />
      </Box>
    )
  return (
    <div>
      {responseData.map((pageData, index) => (
        <AllowedPageItem key={index} href={pageData} />
      ))}
    </div>
  )
}

export default AllowedPages
