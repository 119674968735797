import {
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  ChipField,
  Confirm,
  Button,
  WithRecord,
  useNotify,
  useRedirect,
} from 'react-admin'
import { tokens } from '@/Layout/theme'
import { EResource } from '@/admin/types'
import { EPromocode } from '@/modules/Promocodes/types'
import { EProduct } from '@/modules/Products/types'

import { LabelField } from '@/components/LabelField'
import { EOrder, EConsumer, EMiniprogramTransactionDetail } from '@/modules/Orders/types'
import { useState } from 'react'
import { refundDzOrder } from '@/services/api/methods/refund-dz-order'
import { ORDERS } from '@/services/api/routes'
import { Divider } from '@mui/material'

export const OrderShow = () => {
  const colors = tokens()
  const notify = useNotify()
  const redirect = useRedirect()

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)

  const handleRefund = async (id: any) => {
    setLoading(true)
    try {
      await refundDzOrder(id, notify)
      setOpen(false)
      redirect(ORDERS())
    } catch (error) {
      console.error('Error processing refund:', error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <Show resource={EResource.Orders}>
      <SimpleShowLayout>
        <NumberField source={EOrder.Id} label="ID" textAlign="left" />
        <LabelField source={EOrder.Status} label="Status" />
        <TextField source={`${EOrder.PromoCode}${EPromocode.Id}`} label="Promocode Id" />
        <TextField source={`${EOrder.PromoCode}${EPromocode.Value}`} label="Promocode Value" />
        <TextField source={EOrder.TransactionId} />
        <DateField source={EOrder.CreatedAt} showTime />
        <TextField source={EOrder.PaymentMethod} />
        <TextField source={EOrder.TotalPrice} />
        <TextField source={`${EOrder.Consumer}.${EConsumer.Id}`} sortable={false} label="Consumer ID" />
        <TextField source={`${EOrder.Consumer}.${EConsumer.Phone}`} sortable={false} label="Consumer Phone" />
        <TextField source={`${EOrder.Product}.${EProduct.Id}`} sortable={false} label="Product ID" />
        <ChipField
          sx={{
            backgroundColor: colors.accent.pink,
            color: colors.neutral[0],
          }}
          source={`${EOrder.Product}.${EProduct.EnName}`}
          label="Product English Name"
          sortable={false}
        />
        <Divider />

        <WithRecord
          render={(record) => (
            <>
              {record.miniprogram_transaction_detail ? (
                <div>
                  <h3>Super Qi Transaction Detail</h3>
                  <div>
                    <small>Payment Time: </small>
                    <DateField
                      showTime
                      sortable={false}
                      source={`${EOrder.Miniprogram_transaction_detail}.${EMiniprogramTransactionDetail.PaymentTime}`}
                      label="Payment Time"
                    />
                  </div>
                  <div>
                    <small>Payment Status: </small>
                    <TextField
                      showTime
                      sortable={false}
                      source={`${EOrder.Miniprogram_transaction_detail}.${EMiniprogramTransactionDetail.PaymentStatus}`}
                      label="Payment Status"
                    />
                  </div>
                  <div>
                    <small>Payment Result Status: </small>
                    <TextField
                      showTime
                      sortable={false}
                      source={`${EOrder.Miniprogram_transaction_detail}.${EMiniprogramTransactionDetail.Result}.${EMiniprogramTransactionDetail.ResultStatus}`}
                      label="Payment Status"
                    />
                  </div>
                  <div>
                    <small>Payment Result Code: </small>
                    <TextField
                      showTime
                      sortable={false}
                      source={`${EOrder.Miniprogram_transaction_detail}.${EMiniprogramTransactionDetail.Result}.${EMiniprogramTransactionDetail.ResultCode}`}
                      label="Payment Status"
                    />
                  </div>

                  <div>
                    <small>Payment Result Message: </small>
                    <TextField
                      showTime
                      sortable={false}
                      source={`${EOrder.Miniprogram_transaction_detail}.${EMiniprogramTransactionDetail.Result}.${EMiniprogramTransactionDetail.ResultMessage}`}
                      label="Payment Status"
                    />
                  </div>
                  {record.miniprogram_transaction_detail.transactions &&
                  record.miniprogram_transaction_detail.transactions[0] ? (
                    <div>
                      <small>Transaction Status: </small>

                      <small>{record.miniprogram_transaction_detail.transactions[0].transactionStatus}</small>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </>
          )}
        />

        <WithRecord
          render={(record) => (
            <>
              <Confirm
                isOpen={open}
                loading={loading}
                title={`Refund - ${record.id}`}
                content="Are you sure you want to refund this order?"
                onConfirm={async () => {
                  await handleRefund(record.id)
                }}
                onClose={handleDialogClose}
              />
              {record.refundable ? <Button label="Refund" onClick={handleClick} /> : <div>Not-Refundable</div>}
            </>
          )}
        />
      </SimpleShowLayout>
    </Show>
  )
}
