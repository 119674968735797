import { Create, SimpleForm, TextInput} from 'react-admin'
import { Box } from '@mui/material'
import { ETeam } from './types'
export const TeamsCreate = () => {
  return (
    <Create title="Create new Team" redirect="list">
      <SimpleForm>
        <Box
          display={{
            width: '100%',
            maxWidth: '800px',
          }}
        >
          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={ETeam.ArName} fullWidth required />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={ETeam.KuName} fullWidth required />
            </Box>
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source={ETeam.Logo} fullWidth required />
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  )
}
