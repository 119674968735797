import { Edit, SimpleForm, TextInput } from 'react-admin'
import { ETeam } from './types'
import { Box } from '@mui/material'

export const TeamsEdit = () => {
  return (
    <Edit mutationMode="pessimistic" redirect="list">
      <SimpleForm>
        <Box
          display={{
            width: '100%',
            maxWidth: '800px',
          }}
        >
          <Box
            display={{
              xs: 'block',
              sm: 'flex',
              width: '100%',
            }}
          >
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={ETeam.ArName} fullWidth required />
            </Box>
            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
              <TextInput source={ETeam.KuName} fullWidth required />
            </Box>
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <TextInput source={ETeam.Logo} fullWidth required />
          </Box>
        </Box>
      </SimpleForm>
    </Edit>
  )
}
