import { AuthProvider } from 'react-admin'

import {
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
  setSession,
  getPermissions as getPermissionsAction,
  setPermissions,
  removePermissions,
  setId,
} from '@/services/api/helpers'
import { apiAuthentication } from '@/services/api/methods/apiAuthentication'

const login = async ({ username, password }: { username: string; password: string }) => {
  const { session, accessToken, refreshToken, role, id } = await apiAuthentication({
    username,
    password,
  })

  if (id) {
    setId(id)
  }

  if (session) {
    setSession({
      username,
      id: session,
    })

    return Promise.resolve()
  }

  if (accessToken) {
    setAccessToken(accessToken)
    setRefreshToken(refreshToken)
    if (role) {
      setPermissions(role)
    }
    window.location.reload()
    return Promise.resolve()
  }
  return Promise.reject()
}

const logout = () => {
  removeAccessToken()
  removeRefreshToken()
  removePermissions()
  localStorage.clear()

  return Promise.resolve()
}

const checkError = (error: any) => {
  if (!error) return Promise.resolve()
  const { status } = error
  if (status === 401 || status === 403 || status === 404) {
    localStorage.removeItem('refreshToken')
    return Promise.reject()
  }
  return Promise.resolve()
}

const checkAuth = () => {
  return localStorage.getItem('refreshToken') ? Promise.resolve() : Promise.reject()
}

const getPermissions = () => {
  const role = getPermissionsAction()
  return role ? Promise.resolve(role) : Promise.reject()
}

export const authProvider: AuthProvider = {
  login,
  logout,
  checkAuth,
  checkError,
  getPermissions,
}
