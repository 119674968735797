import { Menu, usePermissions, useCreatePath } from 'react-admin'
import Submenu from '@/components/Submenu'
import {
  SUBCATEGORIES,
  MAINCATEGORIES,
  PRODUCTS,
  BRANDS,
  ORDERS,
  USERS,
  PROMOCODES,
  VARIANTS,
  STADIUMS,
  TEAMS,
  MATCHES,
  MERCHANTS_ORDERS,
  SUPPLIERS_ORDERS,
  ADMINS,
  TICKETS,
  TICKET_ORDERS,
  SETTINGS,
  CONCERTS,
  ALLOWED_PAGES,
  COST_CHANGES,
  ROLES_AND_PERMISSIONS,
  MARKETPLACE_VARIANTS,
} from '@/services/api/routes'
import { tokens } from '@/Layout/theme'

import DvrIcon from '@mui/icons-material/Dvr'
import StorefrontIcon from '@mui/icons-material/Storefront'
import PriceChangeIcon from '@mui/icons-material/PriceChange'
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings'
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined'
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'
import LabelImportantOutlinedIcon from '@mui/icons-material/LabelImportantOutlined'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined'
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined'
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'
import BookOnlineOutlinedIcon from '@mui/icons-material/BookOnlineOutlined'
import StadiumOutlinedIcon from '@mui/icons-material/StadiumOutlined'
import ScoreboardOutlined from '@mui/icons-material/ScoreboardOutlined'
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined'
import AdminPanelIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import LocalActivityIcon from '@mui/icons-material/LocalActivityOutlined'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import { ERole, ERoleRequest } from '@/types/data'
import CodeIcon from '@mui/icons-material/Code'
import { useState, useEffect } from 'react'

interface responseDataTypes {
  id: number
  title: string
}

export const NavMenu = () => {
  const createPath = useCreatePath()
  const [responseData, setResponseData] = useState<responseDataTypes[]>([])

  useEffect(() => {
    const allowedPages = localStorage.getItem('allowedPages')
    setResponseData(allowedPages ? JSON.parse(allowedPages) : [])
  }, [responseData])

  const colors = tokens()
  const { permissions } = usePermissions()

  const styles = {
    backgroundColor: colors.neutral[0],
    '& .MuiMenuItem-root': {
      color: colors.cyanosed[700],
      borderRight: `1px solid ${colors.cyanosed[200]}`,
      fontSize: '14px',

      '&:active': {
        color: colors.primary[800],
        backgroundColor: colors.primary[100],
        fontWeight: 600,
      },
    },
    '& .RaMenuItemLink-active': {
      color: `${colors.primary[800]} !important`,
      backgroundColor: colors.primary[100],
      fontWeight: 600,
      fontSize: '14px',
      padding: 0,

      '& .RaMenuItemLink-icon': {
        color: colors.primary[800],
        marginLeft: 1.6,
      },

      '&::before': {
        content: '""',
        width: '3px',
        height: '32.97px',
        backgroundColor: colors.primary[800],
      },
    },
    marginTop: 2,
  }

  switch (permissions) {
    case ERole.Superadmin:
      return (
        <Menu sx={styles}>
          <Menu.DashboardItem />
          <Menu.Item to={BRANDS()} primaryText="Brands" leftIcon={<StorefrontOutlinedIcon />} />
          <Menu.Item to={PRODUCTS()} primaryText="Products" leftIcon={<LocalMallOutlinedIcon />} />
          <Menu.Item to={VARIANTS()} primaryText="Variants" leftIcon={<DvrIcon />} />
          <Menu.Item to={MARKETPLACE_VARIANTS()} primaryText="Marketplace Variants" leftIcon={<StorefrontIcon />} />
          <Menu.Item to={COST_CHANGES()} primaryText="Cost Change" leftIcon={<PriceChangeIcon />} />
          <Menu.Item to={MAINCATEGORIES()} primaryText="Main Categories" leftIcon={<LabelOutlinedIcon />} />
          <Menu.Item to={SUBCATEGORIES()} primaryText="Sub categories" leftIcon={<LabelImportantOutlinedIcon />} />
          <Menu.Item to={ORDERS()} primaryText="Orders" leftIcon={<ShoppingCartOutlinedIcon />} />
          <Menu.Item to={PROMOCODES()} primaryText="Promocodes" leftIcon={<DiscountOutlinedIcon />} />
          <Submenu primaryText="System" leftIcon={<DisplaySettingsIcon />}>
            <Menu.Item to={ADMINS()} primaryText="Admins" leftIcon={<AdminPanelIcon />} />
            <Menu.Item to={USERS()} primaryText="Users" leftIcon={<PeopleAltOutlinedIcon />} />
            <Menu.Item to={SETTINGS()} primaryText="Settings" leftIcon={<SettingsIcon />} />
            <Menu.Item
              to={ROLES_AND_PERMISSIONS()}
              primaryText="Roles and permissions management"
              leftIcon={<AdminPanelIcon />}
            />
          </Submenu>
          <Submenu primaryText="Ticket Zone" leftIcon={<BookOnlineOutlinedIcon />}>
            <Menu.Item to={STADIUMS()} primaryText="Stadiums" leftIcon={<StadiumOutlinedIcon />} />
            <Menu.Item to={TEAMS()} primaryText="Teams" leftIcon={<PeopleAltOutlinedIcon />} />
            <Menu.Item to={MATCHES()} primaryText="Matches" leftIcon={<ScoreboardOutlined />} />
            <Menu.Item to={CONCERTS()} primaryText="Concerts" leftIcon={<ScoreboardOutlined />} />
            <Menu.Item to={TICKET_ORDERS()} primaryText="Ticket Orders" leftIcon={<LocalActivityIcon />} />
            <Menu.Item to={TICKETS()} primaryText="Tickets" leftIcon={<ConfirmationNumberIcon />} />
          </Submenu>

          {responseData &&
            responseData.map((pageData) => (
              <Menu.Item
                to={createPath({ resource: ALLOWED_PAGES(pageData.id), type: 'list', id: pageData.id })}
                primaryText={`${pageData.title}`}
                key={pageData.id}
                leftIcon={<CodeIcon />}
              />
            ))}
        </Menu>
      )
    case ERole.Admin:
    case ERoleRequest.Admin:
      return (
        <Menu sx={styles}>
          <Menu.DashboardItem />
          <Menu.Item to={BRANDS()} primaryText="Brands" leftIcon={<StorefrontOutlinedIcon />} />
          <Menu.Item to={PRODUCTS()} primaryText="Products" leftIcon={<LocalMallOutlinedIcon />} />
          <Menu.Item to={VARIANTS()} primaryText="Variants" leftIcon={<DvrIcon />} />
          <Menu.Item to={COST_CHANGES()} primaryText="Cost Change" leftIcon={<PriceChangeIcon />} />
          <Menu.Item to={MAINCATEGORIES()} primaryText="Main Categories" leftIcon={<LabelOutlinedIcon />} />
          <Menu.Item to={SUBCATEGORIES()} primaryText="Sub categories" leftIcon={<LabelImportantOutlinedIcon />} />
          <Menu.Item to={ORDERS()} primaryText="Orders" leftIcon={<ShoppingCartOutlinedIcon />} />
          <Menu.Item to={PROMOCODES()} primaryText="Promocodes" leftIcon={<DiscountOutlinedIcon />} />
          <Submenu primaryText="System" leftIcon={<DisplaySettingsIcon />}>
            <Menu.Item to={ADMINS()} primaryText="Admins" leftIcon={<AdminPanelIcon />} />
            <Menu.Item to={USERS()} primaryText="Users" leftIcon={<PeopleAltOutlinedIcon />} />
            <Menu.Item to={SETTINGS()} primaryText="Settings" leftIcon={<SettingsIcon />} />
          </Submenu>
        </Menu>
      )
    case ERoleRequest.TZadmin:
      return (
        <Menu sx={styles}>
          <Submenu primaryText="Ticket Zone" leftIcon={<BookOnlineOutlinedIcon />} isDropdownOpen>
            <Menu.Item to={STADIUMS()} primaryText="Stadiums" leftIcon={<StadiumOutlinedIcon />} />
            <Menu.Item to={TEAMS()} primaryText="Teams" leftIcon={<PeopleAltOutlinedIcon />} />
            <Menu.Item to={MATCHES()} primaryText="Matches" leftIcon={<ScoreboardOutlined />} />
            <Menu.Item to={CONCERTS()} primaryText="Concerts" leftIcon={<ScoreboardOutlined />} />
            <Menu.Item to={TICKET_ORDERS()} primaryText="Ticket Orders" leftIcon={<LocalActivityIcon />} />
            <Menu.Item to={TICKETS()} primaryText="Tickets" leftIcon={<ConfirmationNumberIcon />} />
            {responseData &&
              responseData.map((pageData) => (
                <Menu.Item
                  to={createPath({ resource: ALLOWED_PAGES(pageData.id), type: 'list', id: pageData.id })}
                  primaryText={`${pageData.title}`}
                  key={pageData.id}
                  leftIcon={<CodeIcon />}
                />
              ))}
          </Submenu>
        </Menu>
      )
    case ERole.Merchant:
    case ERoleRequest.Merchant:
      return (
        <Menu sx={styles}>
          <Menu.Item to={MERCHANTS_ORDERS()} primaryText="Orders" leftIcon={<StoreOutlinedIcon />} />
        </Menu>
      )
    case ERole.Supplier:
    case ERoleRequest.Supplier:
      return (
        <Menu sx={styles}>
          <Menu.Item to={SUPPLIERS_ORDERS()} primaryText="Orders" leftIcon={<StoreOutlinedIcon />} />
        </Menu>
      )
    case ERoleRequest.EXTERNAL_ENTITY:
      return (
        <Menu sx={styles}>
          {responseData &&
            responseData.map((pageData) => (
              <Menu.Item
                to={createPath({ resource: ALLOWED_PAGES(pageData.id), type: 'list', id: pageData.id })}
                primaryText={`${pageData.title}`}
                key={pageData.id}
                leftIcon={<CodeIcon />}
              />
            ))}
        </Menu>
      )
    default:
      return <Menu />
  }
}
