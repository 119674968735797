import { useEffect, useState } from 'react'
import { usePermissions, useRedirect } from 'react-admin'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { tokens } from '@/Layout/theme'
import StatBox from '@/components/StatBox'
import BarChart from '@/components/BarChart'
import LineChart from '@/components/LineChart'
import ProgressCircle from '@/components/ProgressCircle'
import EmailIcon from '@mui/icons-material/Email'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import TrafficIcon from '@mui/icons-material/Traffic'
import { ERole, ERoleRequest } from '@/types/data'
import { MERCHANTS_ORDERS, SUPPLIERS_ORDERS, STADIUMS, ALLOWED_PAGES } from '@/services/api/routes'
import { axiosAuthInstance } from '@/services/api/axios'
import { API_AUTH_BASE_URL } from '@/services/api/consts'

type AllowedPage = { id: string; title: string }

export const Dashboard = () => {
  const colors = tokens()
  const { permissions } = usePermissions()
  const redirect = useRedirect()
  const isAdmin = [ERole.Admin, ERoleRequest.Admin, ERole.Superadmin].includes(permissions)
  const [allowedPages, setAllowedPages] = useState<AllowedPage[]>([])
  useEffect(() => {
    axiosAuthInstance
      .get(`${API_AUTH_BASE_URL}/dashboard/pages/getAdminAllowedPages`)
      .then((response) => {
        setAllowedPages(response.data)
        localStorage.setItem('allowedPages', JSON.stringify(response.data))
      })
      .catch((error) => {
        console.error('error:', error)
      })
  }, [])

  async function retryRequest(attempts: number, delay: number) {
    const storedPages = localStorage.getItem('allowedPages')
    const pages = storedPages ? JSON.parse(storedPages) : []
    if (pages.length > 0) {
      setAllowedPages(pages)
    } else if (attempts > 0) {
      await new Promise((resolve) => setTimeout(resolve, delay))
      retryRequest(attempts - 1, delay)
    } else {
      console.error('Failed to retrieve allowed pages after retries')
    }
  }

  const handleRedirection = () => {
    if (isAdmin) return

    const redirectMap: Record<string, string | undefined> = {
      [ERoleRequest.TZadmin]: STADIUMS(),
      [ERoleRequest.Merchant]: MERCHANTS_ORDERS(),
      [ERoleRequest.Supplier]: SUPPLIERS_ORDERS(),
    }

    const redirectPath = redirectMap[permissions] || (allowedPages?.[0]?.id && ALLOWED_PAGES(allowedPages[0].id))

    if (redirectPath) {
      redirect(redirectPath)
    } else {
      retryRequest(5, 1000)
    }
  }

  useEffect(() => {
    handleRedirection()
  }, [permissions, allowedPages])

  if (!isAdmin) return <div />

  return (
    <Card sx={{ padding: '0 24px 20px' }}>
      <CardContent sx={{ color: colors.primary[800], fontSize: '24px' }}>Welcome to Digital Zone Dashboard</CardContent>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gridAutoRows="140px" gap="20px">
        <DashboardStatBox
          title="12,361"
          subtitle="Emails Sent"
          progress={0.75}
          increase="+14%"
          icon={<EmailIcon sx={{ color: colors.primary[300], fontSize: '26px' }} />}
          backgroundColor={colors.neutral[800]}
        />
        <DashboardStatBox
          title="431,225"
          subtitle="Sales Obtained"
          progress={0.5}
          increase="+21%"
          icon={<PointOfSaleIcon sx={{ color: colors.primary[300], fontSize: '26px' }} />}
          backgroundColor={colors.primary[600]}
        />
        <DashboardStatBox
          title="32,441"
          subtitle="New Clients"
          progress={0.3}
          increase="+5%"
          icon={<PersonAddIcon sx={{ color: colors.primary[300], fontSize: '26px' }} />}
          backgroundColor={colors.neutral[800]}
        />
        <DashboardStatBox
          title="1,325,134"
          subtitle="Traffic Received"
          progress={0.8}
          increase="+43%"
          icon={<TrafficIcon sx={{ color: colors.primary[300], fontSize: '26px' }} />}
          backgroundColor={colors.primary[600]}
        />

        <Box gridColumn="span 8" gridRow="span 2" borderRadius="4px" sx={{ backgroundColor: colors.neutral[800] }}>
          <Box mt="25px" p="0 30px" display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h5" fontWeight="600" color={colors.primary[100]}>
                Revenue Generated
              </Typography>
              <Typography variant="h3" fontWeight="bold" color={colors.primary[500]}>
                $59,342.32
              </Typography>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart />
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          overflow="auto"
          borderRadius="4px"
          sx={{ backgroundColor: colors.primary[600] }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            p="15px"
          >
            <Typography color={colors.primary[100]} variant="h5" fontWeight="600">
              Recent Transactions
            </Typography>
          </Box>
        </Box>

        <Box
          gridColumn="span 6"
          gridRow="span 2"
          sx={{ backgroundColor: colors.neutral[800] }}
          borderRadius="4px"
          p="30px"
        >
          <Typography variant="h5" fontWeight="600" color={colors.primary[100]}>
            Campaign
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="center" mt="25px">
            <ProgressCircle size="125" progress={0.75} />
            <Typography variant="h5" color={colors.primary[100]} sx={{ mt: '15px' }}>
              $48,352 revenue generated
            </Typography>
            <Typography color={colors.primary[300]}>Includes extra misc expenditures and costs</Typography>
          </Box>
        </Box>
        <Box gridColumn="span 6" gridRow="span 2" borderRadius="4px" sx={{ backgroundColor: colors.neutral[800] }}>
          <Typography variant="h5" fontWeight="600" sx={{ padding: '30px 30px 0 30px' }} color={colors.primary[100]}>
            Sales Quantity
          </Typography>
          <Box height="250px" mt="-20px">
            <BarChart />
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

const DashboardStatBox = ({
  title,
  subtitle,
  progress,
  increase,
  icon,
  backgroundColor,
}: {
  title: string
  subtitle: string
  progress: number
  increase: string
  icon: JSX.Element
  backgroundColor: string
}) => (
  <Box
    gridColumn="span 3"
    display="flex"
    alignItems="center"
    justifyContent="center"
    borderRadius="4px"
    sx={{ backgroundColor }}
  >
    <StatBox title={title} subtitle={subtitle} progress={progress} increase={increase} icon={icon} />
  </Box>
)
